<template>
<div class="card pointer" :class="{ 'not-selected': currentOption.disabled }">
  <Tooltip :tooltip="option.tooltip" v-if="type === 'two-column'">
    <button class="btn-reset btn-tooltip">
      <i class="fas fa-info-circle"></i>
    </button>
  </Tooltip>
  <Tooltip :tooltip="option.tooltip" v-else-if="step.name !== 'Muster'">
    <button class="btn-reset btn-tooltip">
      <i class="fas fa-info-circle"></i>
    </button>
  </Tooltip>
  <!-- image -->
  <div v-if="type === 'two-column'" @click="onClickOption" class="text-center pt-3" :class="type">
    <img class="card-img-top" :src="$filters.image(option.image, '190x')">
  </div>
  <div v-else-if="step.name == 'Muster'" @click="onClickOption" class="text-center pt-3" :class="type">
    <Tooltip :tooltip="option.tooltip">
      <img class="card-img-top" :src="$filters.image(currentOption.image, '350x')" v-if="type === 'option-2'">
      <img class="card-img-top" :src="$filters.image(currentOption.image, '190x')" v-else>
    </Tooltip>
  </div>
  <div v-else @click="onClickOption" class="text-center pt-3" :class="type">
    <img class="card-img-top" :src="$filters.image(currentOption.image, '350x')" v-if="type === 'option-2'">
    <img class="card-img-top" :src="$filters.image(currentOption.image, '190x')" v-else>
  </div>
  <div @click="onClickOption" class="card-body text-center py-1 px-0">
    <p class="card-title"><span v-if="currentOption.quantity">{{ currentOption.quantity }}x</span>{{ currentOption.name }}</p>
    <div class="text-center description" v-html="currentOption.description"></div>
  </div>
  <spiner v-if="loading" size="2" color="text-primary"/>
  <span v-else>
    <p v-if="!currentOption.sub_options" class="text-center text-danger price-size">
      <span>
        <span class="badge bg-danger" v-if="currentOption.discount > 0">-{{ currentOption.discount }}%</span>
        <span class="option__price option__price--discount" v-if="currentOption.show_zero_price">{{ $filters.currency(price) }}</span>
        <span class="option__price option__price--old" v-if="currentOption.discount > 0">{{ $filters.currency(oldPrice) }}</span>
      </span>
    </p>
    <div v-if="currentOption.selected && currentOption.sub_options" class="ml-2">
      <div v-for="item in currentOption.sub_options" :key="item.id" class="custom-control custom-checkbox">
        <input type="checkbox" class="custom-control-input" :id="`sub-option-${item.id}`" :value="item.id" @change="selectSubOption({ optionId: currentOption.id, subOptionId: item.id })" v-model="item.selected">
        <label class="custom-control-label size-14" :for="`sub-option-${item.id}`">{{ item.name }} - <span class="text-danger">{{ $filters.currency(item.price) }}</span></label>
      </div>
    </div>
  </span>
  <div class="card-footer bg-primary text-white text-center" :class="{'not-selected': !currentOption.selected}" @click="onClickOption">
    <span v-if="currentOption.selected">Ausgewählt</span>
    <span v-else>Auswählen</span>
  </div>
</div>
</template>
<script>
import { computed, toRefs } from '@vue/reactivity'
import { useStore } from 'vuex'
import { onMounted } from '@vue/runtime-core'
export default {
  name: 'SelectOption',
  props: {
    option: {
      required: true,
      type: Object
    },
    type: {
      default: null
    }
  },
  setup (props) {
    const currentOption = toRefs(props).option
    const store = useStore()
    const step = computed(() => {
      return store.getters['configurator/currentStep']
    })
    const priceAll = computed(() => {
      const currentStep = store.getters['configurator/currentStep']
      return currentStep.price_all || false
    })
    const loading = computed(() => {
      return priceAll.value && totalPrice.value.loading
    })
    const totalPrice = computed(() => {
      // const totalPrice = store.getters['configurator/totalPrice']
      return getTotalPrice(currentOption.value.id)
      // return totalPrice(currentOption.value)
    })
    const price = computed(() => {
      const option = currentOption.value
      if (!option) {
        return null
      }
      if (priceAll.value) {
        return totalPrice.value.discounted || totalPrice.value.total
      }
      if (option.price) {
        if (!isNaN) {
          return option.price.discounted
        } else {
          if (option.discount > 0) {
            const discount = (Number.parseFloat(option.discount) / 100) * option.price.self
            let totalDiscount = 0
            totalDiscount = option.price.self - discount
            return totalDiscount
          } else {
            return option.price.self
          }
        }
      }
      return 0
    })
    const oldPrice = computed(() => {
      const option = currentOption.value
      if (!option) {
        return null
      }
      if (priceAll.value) {
        return totalPrice.value.old_price
      }
      if (option.price) {
        return option.price.self
      }
      return 0
    })
    // methods
    function onClickOption () {
      if (currentOption.value.disabled) {
        return
      }
      if (currentOption.value.type === 'check') {
        store.dispatch('configurator/toggleOption', currentOption.value)
      } else {
        store.dispatch('configurator/selectOption', currentOption.value)
      }
    }
    function selectSubOption ({ optionId, subOptionId, e }) {
      store.dispatch('configurator/selectSubOption', { optionId: optionId, subOptionId: subOptionId, e: e })
    }
    function getTotalPrice (id) {
      const prices = store.state.configurator.totalPrices
      const price = prices.find(o => o.id === id)
      return price
    }
    onMounted(() => {
      if (priceAll.value) {
        store.dispatch('configurator/calculatePriceAll', { option: currentOption.value })
      }
    })
    return {
      step,
      currentOption,
      price,
      oldPrice,
      loading,
      onClickOption,
      selectSubOption
    }
  }
}
</script>
<style lang="scss" scoped>
.card {
  height: 100%;
}
.not-selected {
  opacity: .5;
}
.option-2 {
  .card-img-top {
    max-height: 350px !important;
    max-width: 350px !important;
  }
}
</style>
